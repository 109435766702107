var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.onClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateSubmit($event)
                }
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文库名称：",
                            prop: "name",
                            rules: [
                              { required: true, message: "请输入文库名称" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "20"
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物种：",
                            prop: "species",
                            rules: [{ required: true, message: "请输入物种" }]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "20"
                            },
                            model: {
                              value: _vm.form.species,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "species", $$v)
                              },
                              expression: "form.species"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文库片段大小(bp)：",
                            prop: "fragmentSize",
                            rules: [
                              {
                                required: true,
                                message: "请输入文库片段大小(bp)"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "20"
                            },
                            model: {
                              value: _vm.form.fragmentSize,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "fragmentSize", $$v)
                              },
                              expression: "form.fragmentSize"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "浓度(ng/ul)：",
                            prop: "concentration",
                            rules: [
                              { required: true, message: "请输入浓度(ng/ul)" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "20"
                            },
                            model: {
                              value: _vm.form.concentration,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "concentration", $$v)
                              },
                              expression: "form.concentration"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "体积(μL)：",
                            prop: "volume",
                            rules: [
                              { required: true, message: "请输入体积(μL)" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "20"
                            },
                            model: {
                              value: _vm.form.volume,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "volume", $$v)
                              },
                              expression: "form.volume"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否平衡文库：",
                            prop: "isBalance",
                            rules: [
                              { required: true, message: "请选择是否平衡文库" }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.isBalance,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "isBalance", $$v)
                                },
                                expression: "form.isBalance"
                              }
                            },
                            _vm._l(_vm.isBalance, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否环化：",
                            prop: "isCircular",
                            rules: [
                              { required: true, message: "请选择是否环化" }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.isCircular,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "isCircular", $$v)
                                },
                                expression: "form.isCircular"
                              }
                            },
                            _vm._l(_vm.isCircular, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "测序平台：",
                            prop: "sequencingPlatform",
                            rules: [
                              { required: true, message: "请输入测序平台" }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.sequencingPlatform,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sequencingPlatform", $$v)
                                },
                                expression: "form.sequencingPlatform"
                              }
                            },
                            _vm._l(_vm.sequencingPlatform, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "测序策略：",
                            prop: "sequencingStrategy",
                            rules: [
                              { required: true, message: "请输入测序策略" }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.sequencingStrategy,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sequencingStrategy", $$v)
                                },
                                expression: "form.sequencingStrategy"
                              }
                            },
                            _vm._l(_vm.sequencingStrategy, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "测序数据量：",
                            prop: "sequencingData",
                            rules: [
                              { required: true, message: "请输入测序数据量" }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "10"
                            },
                            model: {
                              value: _vm.form.sequencingData,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sequencingData", $$v)
                              },
                              expression: "form.sequencingData"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数据量单位：",
                            prop: "sequencingDataUnit",
                            rules: [
                              { required: true, message: "请输入数据量单位" }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.sequencingDataUnit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sequencingDataUnit", $$v)
                                },
                                expression: "form.sequencingDataUnit"
                              }
                            },
                            _vm._l(_vm.sequencingDataUnit, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showStorage
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "样本储位：",
                                prop: "storage",
                                rules: [
                                  { required: true, message: "请输入样本储位" }
                                ]
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  autocomplete: "off",
                                  maxlength: "10"
                                },
                                model: {
                                  value: _vm.form.storage,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "storage", $$v)
                                  },
                                  expression: "form.storage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注：", prop: "comment" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              maxlength: "50"
                            },
                            model: {
                              value: _vm.form.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "comment", $$v)
                              },
                              expression: "form.comment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.validateSubmit }
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }